import { gql } from 'graphql-request';

import { SelectedMaterialUUID } from '../selectedMaterialsTypes';

export interface DownloadSelectedMaterialQueryResponse {
  uuid: SelectedMaterialUUID;
}

export const DOWNLOAD_SELECTED_MATERIAL_QUERY = gql`
  mutation DownloadSelectedMaterial($uuid: ID!, $deviceNanoId: String!) {
    downloadSelectedMaterial(
      input: { uuid: $uuid, deviceNanoId: $deviceNanoId }
    ) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
