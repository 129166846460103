import { CreatedAt, DeviceNanoId, ID, NanoID, UUID } from '../../../types';

import {
  CategoryID,
  CategoryLocalizedName,
  CategoryName,
  CategoryUUID
} from '../../categories/categoriesTypes';

import {
  ImageFile,
  ImageName,
  ImageSize,
  ImageUUID
} from '../../images/imagesTypes';

import {
  UserCurrentTeamNanoID,
  UserFullName,
  UserID,
  UserName,
  UserNanoID
} from '../../users/usersTypes';

import { ImageID } from '../../images/imagesTypes';

import {
  MaxFileFile,
  MaxFileID,
  MaxFileName,
  MaxFileSize,
  MaxFileUUID
} from '../../maxFiles/maxFilesTypes';

export type MaterialID = ID;
export type MaterialUUID = UUID;
export type MaterialNanoID = NanoID;

export type MaterialBlocked = boolean;
export type MaterialFavorite = boolean;
export type MaterialName = string;
export type MaterialSku = string;
export type MaterialNda = boolean;
export type MaterialCreatedAt = CreatedAt;
export type MaterialDeviceNanoId = DeviceNanoId;

export type MaterialImageID = ImageID;
export type MaterialImageName = ImageName;
export type MaterialImageSize = ImageSize;
export type MaterialImageUUID = ImageUUID;
export type MaterialImageFile = ImageFile;

export type MaterialMaxFileID = MaxFileID;
export type MaterialMaxFileName = MaxFileName;
export type MaterialMaxFileSize = MaxFileSize;
export type MaterialMaxFileUUID = MaxFileUUID;
export type MaterialMaxFileFile = MaxFileFile;

export type MaterialBrandID = CategoryID;
export type MaterialBrandUUID = CategoryUUID;
export type MaterialBrandName = CategoryName;
export type MaterialBrandLocalizedName = CategoryLocalizedName;

export type MaterialCategoryID = CategoryID;
export type MaterialCategoryName = CategoryName;

export type MaterialClientID = UserID;
export type MaterialClientNanoId = UserNanoID;
export type MaterialClientName = UserName;
export type MaterialClientFullName = UserFullName;
export type MaterialClientCurrentTeamNanoId = UserCurrentTeamNanoID;

export const enum MaterialFields {
  NAME = 'name',
  SKU = 'sku',
  CATEGORY_IDS = 'categoryIds',
  BRAND_ID = 'brandId',
  CLIENT_IDS = 'clientIds',
  NDA = 'nda',
  IMAGE_IDS = 'imageIds',
  MAX_FILE_IDS = 'maxFileIds'
}
