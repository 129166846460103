import { useCallback } from 'react';

import { TaskUUID } from '../../../tasks/tasksTypes';
import { MaterialID, MaterialUUID, MaterialNanoID } from '../../materialsTypes';

import {
  FetchMaterialsSetByUserIdQueryResponse,
  FETCH_MATERIALS_SET_BY_USER_ID_QUERY
} from '../../../materialsSets/queries/fetchMaterialsSetByUserId.query';
import { TOGGLE_MATERIAL_IN_MATERIALS_SET_QUERY } from '../../../materialsSets/queries/toggleMaterialInMaterialsSet.query';
import {
  SubmitSelectedMaterialsToTaskQueryResponse,
  SUBMIT_SELECTED_MATERIALS_TO_TASK_QUERY
} from '../../../tasks/queries/submitSelectedMaterialsToTask.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useMaterialsSetByUserId } from '../../../materialsSets/hooks/useMaterialsSetByUserId';
import { useToggleMaterialInMaterialsSet } from '../../../materialsSets/hooks/useToggleMaterialInMaterialsSet';
import { useSubmitSelectedMaterialsToTask } from '../../../tasks/hooks/useSubmitSelectedMaterialsToTask';

import { MaterialsSetCache } from '../../../materialsSets/MaterialsSetCache';
import { MaterialsSetScope } from '../../../materialsSets/MaterialsSetScope';

interface SubmitMaterialToTaskOptionsMaterial {
  uuid: MaterialUUID;
  id: MaterialID;
  nanoId: MaterialNanoID;
}

interface SubmitMaterialToTaskOptions {
  material: SubmitMaterialToTaskOptionsMaterial;
}

function useSubmitMaterialToTask({ material }: SubmitMaterialToTaskOptions) {
  const currentUser = useCurrentUser();

  const {
    materialsSet,
    materialsSetError,
    materialsSetLoading,
    updateMaterialsSetCache
  } = useMaterialsSetByUserId<FetchMaterialsSetByUserIdQueryResponse>({
    cacheKey: MaterialsSetCache.submitToTaskCacheKey(),
    query: FETCH_MATERIALS_SET_BY_USER_ID_QUERY,
    userId: currentUser.id,
    scope: MaterialsSetScope.submitToTaskScope(currentUser.id)
  });

  const {
    toggleMaterialInMaterialsSetErrorMessage,
    toggleMaterialInMaterialsSet,
    toggleMaterialInMaterialsSetLoading
  } = useToggleMaterialInMaterialsSet({
    query: TOGGLE_MATERIAL_IN_MATERIALS_SET_QUERY,
    cacheKeys: [MaterialsSetCache.submitToTaskCacheKey()],
    material,
    updateMaterialsSetCache
  });

  const {
    submitSelectedMaterialsToTaskErrorMessage,
    submitSelectedMaterialsToTaskLoading,
    submitSelectedMaterialsToTask,
    submitSelectedMaterialsToTaskReset
  } = useSubmitSelectedMaterialsToTask<SubmitSelectedMaterialsToTaskQueryResponse>(
    {
      query: SUBMIT_SELECTED_MATERIALS_TO_TASK_QUERY,
      cacheKeys: [MaterialsSetCache.submitToTaskCacheKey()]
    }
  );

  const submitMaterialToTask = useCallback<
    (taskUuid: TaskUUID) => Promise<unknown>
  >(
    async (taskUuid) => {
      const data = await toggleMaterialInMaterialsSet({
        materialId: material.nanoId,
        uuid: materialsSet.uuid
      });

      return submitSelectedMaterialsToTask({
        uuid: taskUuid,
        selectedMaterialIds:
          data.toggleMaterialInMaterialsSet?.record?.selectedMaterials?.map(
            (selectedMaterial) => selectedMaterial.id
          )
      });
    },
    [
      material.nanoId,
      materialsSet,
      submitSelectedMaterialsToTask,
      toggleMaterialInMaterialsSet
    ]
  );

  return {
    submitMaterialToTask,
    submitMaterialToTaskReset: submitSelectedMaterialsToTaskReset,
    submitMaterialToTaskError:
      materialsSetError ||
      toggleMaterialInMaterialsSetErrorMessage ||
      submitSelectedMaterialsToTaskErrorMessage,
    submitMaterialToTaskIsLoading:
      materialsSetLoading ||
      toggleMaterialInMaterialsSetLoading ||
      submitSelectedMaterialsToTaskLoading
  };
}

export default useSubmitMaterialToTask;
