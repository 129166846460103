import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { MaterialUUID } from '../../../materialsTypes';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';

import { useDownloadMaterial } from '../../../hooks/useDownloadMaterial';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface DownloadMaterialButtonProps {
  materialUuid: MaterialUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
  tooltipPlacement?: TooltipPlacement;
}

function DownloadMaterialButton({
  materialUuid,
  className,
  iconClassName,
  icon,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: DownloadMaterialButtonProps) {
  const {
    downloadMaterialLoading,
    downloadMaterialErrorMessage,
    downloadMaterial
  } = useDownloadMaterial();

  useShowToastOnErrorChange({ error: downloadMaterialErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();

  const handleClick = useCallback(() => {
    downloadMaterial({
      uuid: materialUuid,
      deviceNanoId: downloadNanoId
    });
  }, [materialUuid, downloadNanoId, downloadMaterial]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      disabled={downloadMaterialLoading}
      onClick={handleClick}
      tooltipI18nText={tooltipI18nText}
      tooltipSingleton={tooltipSingleton}
      tooltipPlacement={tooltipPlacement}
    />
  );
}

export default DownloadMaterialButton;
