import { useCallback } from 'react';
import size from 'lodash/size';
import compact from 'lodash/compact';

import {
  UpdateMaterialsSetCacheAction,
  FetchMaterialsSetsCacheKeys
} from '../../../../../../materialsSets/materialsSetsTypes';

import { OnSelectedMaterialsSidebarCloseAction } from '../../../../../../materials/materialsTypes';

import {
  TOGGLE_MATERIAL_IN_MATERIALS_SET_QUERY,
  ToggleMaterialInMaterialsSetQueryResponse
} from '../../../../../../materialsSets/queries/toggleMaterialInMaterialsSet.query';

import { useToggleMaterialInMaterialsSet } from '../../../../../../materialsSets/hooks/useToggleMaterialInMaterialsSet';

import { useToastNotification } from '../../../../../../../common/hooks/useToastNotification';

import { parseRequestError } from '../../../../../../../utils/parseRequestError';

import { MaterialsSetCache } from '../../../../../../materialsSets/MaterialsSetCache';

import {
  SelectedMaterialsListItemDeleteButtonSelectedMaterial,
  SelectedMaterialsListItemDeleteButtonMaterialsSet
} from './SelectedMaterialsListItemDeleteButton.types';

interface UseSelectedMaterialsListItemDeleteButtonOptions {
  selectedMaterial: SelectedMaterialsListItemDeleteButtonSelectedMaterial;
  materialsSet: SelectedMaterialsListItemDeleteButtonMaterialsSet;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  onSelectedMaterialsSidebarClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<SelectedMaterialsListItemDeleteButtonMaterialsSet>;
}

function useSelectedMaterialsListItemDeleteButton({
  selectedMaterial,
  materialsSet,
  materialsSetCacheKeys,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache
}: UseSelectedMaterialsListItemDeleteButtonOptions) {
  const { toggleMaterialInMaterialsSet } =
    useToggleMaterialInMaterialsSet<ToggleMaterialInMaterialsSetQueryResponse>({
      query: TOGGLE_MATERIAL_IN_MATERIALS_SET_QUERY,
      cacheKeys: compact([
        MaterialsSetCache.showCacheKey(),
        ...(materialsSetCacheKeys || [])
      ]),
      material: selectedMaterial.material,
      updateMaterialsSetCache
    });

  const { showToastNotification } = useToastNotification({
    appearance: 'error'
  });

  const selectedMaterialsAmount = size(materialsSet?.selectedMaterials);

  const handleAfterToggle = useCallback(() => {
    if (selectedMaterialsAmount === 1) {
      onSelectedMaterialsSidebarClose();
    }
  }, [selectedMaterialsAmount, onSelectedMaterialsSidebarClose]);

  const materialsSetUuid = materialsSet?.uuid;
  const materialNanoId = selectedMaterial?.material?.nanoId;

  const handleToggleSelected = useCallback(() => {
    if (!materialsSetUuid || !materialNanoId) {
      return;
    }

    toggleMaterialInMaterialsSet({
      uuid: materialsSetUuid,
      materialId: materialNanoId
    }).catch((error) => {
      showToastNotification(parseRequestError(error));
    });

    handleAfterToggle();
  }, [
    materialsSetUuid,
    materialNanoId,
    toggleMaterialInMaterialsSet,
    showToastNotification,
    handleAfterToggle
  ]);

  return {
    handleToggleSelected
  };
}

export default useSelectedMaterialsListItemDeleteButton;
