import { useCallback } from 'react';

import { MaterialID, MaterialUUID, MaterialNanoID } from '../../materialsTypes';
import { ProjectUUID } from '../../../projects/projectsTypes';
import { TaskUUID } from '../../../tasks/tasksTypes';

import { useSubmitMaterialToProject } from '../useSubmitMaterialToProject';
import { useSubmitMaterialToTask } from '../useSubmitMaterialToTask';

interface SubmitMaterialToProjectOrTaskOptionsMaterial {
  uuid: MaterialUUID;
  id: MaterialID;
  nanoId: MaterialNanoID;
}

interface SubmitMaterialToProjectOrTaskOptions {
  material: SubmitMaterialToProjectOrTaskOptionsMaterial;
}

interface HandleSubmitMaterialToProjectOrTaskProps {
  projectUuid: ProjectUUID;
  taskUuid: TaskUUID | null;
}

function useSubmitMaterialToProjectOrTask({
  material
}: SubmitMaterialToProjectOrTaskOptions) {
  const {
    submitMaterialToTask,
    submitMaterialToTaskError,
    submitMaterialToTaskIsLoading,
    submitMaterialToTaskReset
  } = useSubmitMaterialToTask({ material });

  const {
    submitMaterialToProject,
    submitMaterialToProjectError,
    submitMaterialToProjectIsLoading,
    submitMaterialToProjectReset
  } = useSubmitMaterialToProject({ material });

  const submitMaterialToProjectOrTask = useCallback<
    ({
      projectUuid,
      taskUuid
    }: HandleSubmitMaterialToProjectOrTaskProps) => Promise<unknown>
  >(
    async ({ projectUuid, taskUuid }) => {
      if (taskUuid) {
        return submitMaterialToTask(taskUuid);
      }

      return submitMaterialToProject(projectUuid);
    },
    [submitMaterialToTask, submitMaterialToProject]
  );

  const submitMaterialToProjectOrTaskReset = useCallback<() => void>(() => {
    submitMaterialToProjectReset();
    submitMaterialToTaskReset();
  }, [submitMaterialToProjectReset, submitMaterialToTaskReset]);

  return {
    submitMaterialToProjectOrTask,
    submitMaterialToProjectOrTaskReset,
    submitMaterialToProjectOrTaskError:
      submitMaterialToTaskError || submitMaterialToProjectError,
    submitMaterialToProjectOrTaskIsLoading:
      submitMaterialToTaskIsLoading || submitMaterialToProjectIsLoading
  };
}

export default useSubmitMaterialToProjectOrTask;
