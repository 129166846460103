import React from 'react';

import { Translate } from '../../../../../../../helpers/Translate';

import { MaterialsListItemNdaMaterial } from './MaterialsListItemNda.types';

interface MaterialsListItemNdaProps {
  material: MaterialsListItemNdaMaterial;
}

function MaterialsListItemNda({ material }: MaterialsListItemNdaProps) {
  if (!material.nda) {
    return null;
  }

  return (
    <span
      id="attachment-2-nda"
      className="bg-yellow-200 px-1 py-0.5 text-xs rounded uppercase mr-1"
    >
      <Translate id="forms.fields.nda" />
    </span>
  );
}

export default MaterialsListItemNda;
