import { gql } from 'graphql-request';

import { MaterialFavorite } from '../materialsTypes';

export interface ToggleMaterialFavoriteQueryResponse {
  favorite: MaterialFavorite;
}

export const TOGGLE_MATERIAL_FAVORITE_QUERY = gql`
  mutation ToggleMaterialFavorite($uuid: ID!) {
    toggleMaterialFavorite(input: { uuid: $uuid }) {
      clientMutationId
      status
      recordUuid
      record {
        favorite
      }
      errors {
        fullMessages
      }
    }
  }
`;
