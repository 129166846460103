import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { FetchMaterialsSetsCacheKeys } from '../../../../../../materialsSets/materialsSetsTypes';

import { FetchMaterialsCacheKeys } from '../../../../../../materials/materialsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useSelectedMaterialsListItemLightboxFavoriteButton from './useSelectedMaterialsListItemLightboxFavoriteButton';

import { SelectedMaterialsListItemLightboxFavoriteButtonSelectedMaterial } from './SelectedMaterialsListItemLightboxFavoriteButton.types';

import { words } from '../../../../../../../locales/keys';

interface SelectedMaterialsListItemLightboxFavoriteButtonProps {
  selectedMaterial: SelectedMaterialsListItemLightboxFavoriteButtonSelectedMaterial;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
}

function SelectedMaterialsListItemLightboxFavoriteButton({
  selectedMaterial,
  materialsCacheKeys,
  materialsSetCacheKeys
}: SelectedMaterialsListItemLightboxFavoriteButtonProps) {
  const { toggleMaterialFavoriteLoading, handleToggleFavorite } =
    useSelectedMaterialsListItemLightboxFavoriteButton({
      selectedMaterial,
      materialsCacheKeys,
      materialsSetCacheKeys
    });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white',
        selectedMaterial.material?.favorite ? 'text-pink-600' : null
      )}
      icon={
        selectedMaterial.material?.favorite
          ? IconsEnum.HEART_SOLID
          : IconsEnum.HEART_OUTLINE
      }
      iconClassName={cl(
        'h-6 w-6',
        selectedMaterial.material?.favorite ? 'p-0.5' : null
      )}
      tooltipSingleton
      tooltipI18nText={
        selectedMaterial.material?.favorite
          ? words.removeFromFavorites
          : words.addToFavorites
      }
      disabled={toggleMaterialFavoriteLoading}
      onClick={handleToggleFavorite}
    />
  );
}

export default SelectedMaterialsListItemLightboxFavoriteButton;
