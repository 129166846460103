import { useCallback } from 'react';
import { ClientError } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';
import find from 'lodash/find';
import without from 'lodash/without';
import isArray from 'lodash/isArray';

import { UUID, NanoID } from '../../../../types';

import {
  ToggleMaterialInMaterialsSetGqlQuery,
  ToggleMaterialInMaterialsSetGqlStatus,
  ToggleMaterialInMaterialsSetCacheKeys,
  ToggleMaterialInMaterialsSetGqlError,
  MaterialsSetSelectedMaterialsUUID,
  UpdateMaterialsSetCacheAction
} from '../../materialsSetsTypes';

import { updateItem } from '../../../common/hooks/base/reactQuery/baseActions/updateItem';

import { parseRequestError } from '../../../../utils/parseRequestError';

import { TempSelectedMaterial } from './utils/TempSelectedMaterial';

import { ToggleMaterialInMaterialsSetMaterial } from './useToggleMaterialInMaterialsSet.types';

interface ToggleMaterialInMaterialsSetInput {
  clientMutationId?: string;
  uuid: UUID;
  materialId: NanoID;
}

interface ToggleMaterialInMaterialsSetError {
  fullMessages: ToggleMaterialInMaterialsSetGqlError;
}

interface ToggleMaterialInMaterialsSetResponse<
  ToggleMaterialInMaterialsSetItemType
> {
  toggleMaterialInMaterialsSet: {
    status: ToggleMaterialInMaterialsSetGqlStatus;
    record: ToggleMaterialInMaterialsSetItemType;
    errors: ToggleMaterialInMaterialsSetError;
  };
}

interface ToggleMaterialInMaterialsSetContext {
  rollback?: () => void;
}

interface ToggleMaterialInMaterialsSetOptions<
  ToggleMaterialInMaterialsSetItemType extends ToggleMaterialInMaterialsSetItem
> {
  query: ToggleMaterialInMaterialsSetGqlQuery;
  cacheKeys: ToggleMaterialInMaterialsSetCacheKeys;
  material: ToggleMaterialInMaterialsSetMaterial;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<ToggleMaterialInMaterialsSetItemType>;
}

const action = 'toggleMaterialInMaterialsSet';

interface ToggleMaterialInMaterialsSetItem {
  selectedMaterials: {
    uuid: MaterialsSetSelectedMaterialsUUID;
  }[];
}

function useToggleMaterialInMaterialsSet<
  ToggleMaterialInMaterialsSetItemType extends ToggleMaterialInMaterialsSetItem
>({
  query,
  cacheKeys,
  material,
  updateMaterialsSetCache
}: ToggleMaterialInMaterialsSetOptions<ToggleMaterialInMaterialsSetItemType>) {
  const queryClient = useQueryClient();

  const handleOptimisticUpdate = useCallback<() => null | (() => void)>(() => {
    return updateMaterialsSetCache({
      updateFunction: (prevMaterialsSet) => {
        const prevSelectedMaterials = prevMaterialsSet.selectedMaterials;

        const selectedMaterial = find(prevSelectedMaterials, [
          'material.uuid',
          material.uuid
        ]);

        const newSelectedMaterials = selectedMaterial
          ? without(prevSelectedMaterials, selectedMaterial)
          : [...prevSelectedMaterials, TempSelectedMaterial.create(material)];

        return {
          ...prevMaterialsSet,
          selectedMaterials: newSelectedMaterials
        };
      }
    });
  }, [material, updateMaterialsSetCache]);

  const { error, isLoading, mutateAsync, reset } = useMutation<
    ToggleMaterialInMaterialsSetResponse<ToggleMaterialInMaterialsSetItemType>,
    ToggleMaterialInMaterialsSetError | ClientError | Error,
    ToggleMaterialInMaterialsSetInput,
    ToggleMaterialInMaterialsSetContext
  >(
    (queryInput) =>
      updateItem<
        ToggleMaterialInMaterialsSetInput,
        ToggleMaterialInMaterialsSetResponse<ToggleMaterialInMaterialsSetItemType>
      >({
        query,
        queryInput,
        action
      }),
    {
      onMutate: () => {
        const rollback = handleOptimisticUpdate() || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSettled: () => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
      }
    }
  );

  return {
    toggleMaterialInMaterialsSetError: error,
    toggleMaterialInMaterialsSetLoading: isLoading,
    toggleMaterialInMaterialsSetErrorMessage: parseRequestError(error),
    toggleMaterialInMaterialsSet: mutateAsync,
    toggleMaterialInMaterialsSetReset: reset
  };
}

export default useToggleMaterialInMaterialsSet;
