import { useCallback } from 'react';
import { ClientError } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';

import { UpdateMaterialsSetCacheAction } from '../../materialsSetsTypes';

import {
  EmptyMaterialsSetInput,
  EmptyMaterialsSetError,
  EmptyMaterialsSetResponse,
  action,
  EMPTY_MATERIALS_SET_QUERY,
  EmptyMaterialsSetQueryResponse
} from '../../queries/emptyMaterialsSet.query';

import { updateItem } from '../../../common/hooks/base/reactQuery/baseActions/updateItem';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface EmptyMaterialsSetContext {
  rollback?: () => void;
}

interface EmptyMaterialsSetOptions {
  itemCacheKey: string;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<EmptyMaterialsSetQueryResponse>;
}

function useEmptyMaterialsSet({
  itemCacheKey,
  updateMaterialsSetCache
}: EmptyMaterialsSetOptions) {
  const queryClient = useQueryClient();

  const handleOptimisticUpdate = useCallback<() => null | (() => void)>(() => {
    return updateMaterialsSetCache({
      updateFunction: (prevMaterialSet) => ({
        ...prevMaterialSet,
        selectedMaterials: []
      })
    });
  }, [updateMaterialsSetCache]);

  const { error, isLoading, mutateAsync, reset } = useMutation<
    EmptyMaterialsSetResponse,
    EmptyMaterialsSetError | ClientError | Error,
    EmptyMaterialsSetInput,
    EmptyMaterialsSetContext
  >(
    (queryInput) =>
      updateItem<EmptyMaterialsSetInput, EmptyMaterialsSetResponse>({
        query: EMPTY_MATERIALS_SET_QUERY,
        queryInput,
        action
      }),
    {
      onMutate: () => {
        const rollback = handleOptimisticUpdate() || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSettled: () => {
        if (itemCacheKey) {
          queryClient.invalidateQueries(itemCacheKey);
        }
      }
    }
  );

  return {
    emptyMaterialsSetError: error,
    emptyMaterialsSetLoading: isLoading,
    emptyMaterialsSetErrorMessage: parseRequestError(error),
    emptyMaterialsSet: mutateAsync,
    emptyMaterialsSetReset: reset
  };
}

export default useEmptyMaterialsSet;
