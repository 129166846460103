import { FetchMaterialsSetMaterialClientId } from './materialsSetsTypes';

import { TeamNanoID } from '../teams/teamsTypes';

export class MaterialsSetCache {
  static showCacheKey() {
    return 'materialsSet';
  }

  static submitToTaskCacheKey() {
    return 'materialSet-submit-to-task';
  }

  static submitToProjectCacheKey() {
    return 'materialSet-submit-to-project';
  }

  static showUserLibraryCacheKey(
    materialClientId: FetchMaterialsSetMaterialClientId
  ) {
    return `materialsSetUserLibrary-${materialClientId}`;
  }

  static showCompanyLibraryCacheKey(companyNanoId: TeamNanoID) {
    return `materialsSetCompanyLibrary-${companyNanoId}`;
  }
}
