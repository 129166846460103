import React, { Fragment } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchMaterialsCacheKeys,
  OnSelectedMaterialsSidebarCloseAction
} from '../../../../../../materials/materialsTypes';

import {
  UpdateMaterialsSetCacheAction,
  FetchMaterialsSetsCacheKeys
} from '../../../../../../materialsSets/materialsSetsTypes';

import { SelectedMaterialsListItemDeleteButton } from '../../../SelectedMaterialsListItem/components/SelectedMaterialsListItemDeleteButton';

import { DownloadSelectedMaterialButton } from '../../../../buttons/DownloadSelectedMaterialButton';

import { SelectedMaterialsListItemLightboxFavoriteButton } from '../SelectedMaterialsListItemLightboxFavoriteButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  SelectedMaterialsListItemLightboxBottomButtonsSelectedMaterial,
  SelectedMaterialsListItemLightboxBottomButtonsMaterialsSet
} from './SelectedMaterialsListItemLightboxBottomButtons.types';

import { SelectedMaterialsPermissions } from '../../../../../selectedMaterialsConstants';

import { words } from '../../../../../../../locales/keys';

interface SelectedMaterialsListItemLightboxBottomButtonsProps {
  selectedMaterial: SelectedMaterialsListItemLightboxBottomButtonsSelectedMaterial;
  materialsSet: SelectedMaterialsListItemLightboxBottomButtonsMaterialsSet | null;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  onSelectedMaterialsSidebarClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<SelectedMaterialsListItemLightboxBottomButtonsMaterialsSet>;
}

function SelectedMaterialsListItemLightboxBottomButtons({
  selectedMaterial,
  materialsSet,
  materialsCacheKeys,
  materialsSetCacheKeys,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache
}: SelectedMaterialsListItemLightboxBottomButtonsProps) {
  return (
    <Fragment>
      <SelectedMaterialsListItemDeleteButton
        selectedMaterial={selectedMaterial}
        materialsSet={materialsSet}
        materialsSetCacheKeys={materialsSetCacheKeys}
        className="py-2 font-medium inline-flex space-x-1 items-center rounded-md px-4 text-gray-800 hover:bg-gray-300 bg-gray-200 px-4 focus:ring-white focus:ring-offset-black"
        i18nText={words.remove}
        onSelectedMaterialsSidebarClose={onSelectedMaterialsSidebarClose}
        updateMaterialsSetCache={updateMaterialsSetCache}
      />
      <CheckPermissions
        action={
          SelectedMaterialsPermissions.READ_SELECTED_MATERIAL_FAVORITE_BUTTON
        }
      >
        <SelectedMaterialsListItemLightboxFavoriteButton
          selectedMaterial={selectedMaterial}
          materialsCacheKeys={materialsCacheKeys}
          materialsSetCacheKeys={materialsSetCacheKeys}
        />
      </CheckPermissions>
      <CheckPermissions
        action={
          SelectedMaterialsPermissions.READ_SELECTED_MATERIALS_DOWNLOAD_BUTTON
        }
      >
        <DownloadSelectedMaterialButton
          selectedMaterialUuid={selectedMaterial.uuid}
          className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
          icon={IconsEnum.DOWNLOAD_OUTLINE}
          iconClassName="h-6 w-6"
          tooltipSingleton
          tooltipI18nText={words.download}
        />
      </CheckPermissions>
    </Fragment>
  );
}

export default SelectedMaterialsListItemLightboxBottomButtons;
