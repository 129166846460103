import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface SubmitSelectedMaterialsToTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const SUBMIT_SELECTED_MATERIALS_TO_TASK_QUERY = gql`
  mutation SubmitSelectedMaterialsToTask(
    $uuid: ID!
    $selectedMaterialIds: [ID!]!
  ) {
    submitSelectedMaterialsToTask(
      input: { uuid: $uuid, selectedMaterialIds: $selectedMaterialIds }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
