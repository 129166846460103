import { useCallback } from 'react';
import size from 'lodash/size';

import {
  UpdateMaterialsSetCacheAction,
  FetchMaterialsSetsCacheKey
} from '../../../materialsSets/materialsSetsTypes';

import { OnSelectedMaterialsSidebarCloseAction } from '../../../materials/materialsTypes';

import { useEmptyMaterialsSet } from '../../../materialsSets/hooks/useEmptyMaterialsSet';

import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';

import { MaterialsSetCache } from '../../../materialsSets/MaterialsSetCache';

import {
  SelectedMaterialsFloatingCounterMaterialsSet,
  SelectedMaterialsFloatingCounterOnMaterialsSelect
} from './SelectedMaterialsFloatingCounter.types';

interface UseMaterialsIndexPageSelectedMaterialsOptions {
  materialsSet: SelectedMaterialsFloatingCounterMaterialsSet;
  materialsSetCacheKey?: FetchMaterialsSetsCacheKey;
  onClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<SelectedMaterialsFloatingCounterMaterialsSet>;
  onMaterialsSelect?: SelectedMaterialsFloatingCounterOnMaterialsSelect;
}

function useSelectedMaterialsFloatingCounter({
  materialsSet,
  materialsSetCacheKey,
  onClose,
  updateMaterialsSetCache,
  onMaterialsSelect
}: UseMaterialsIndexPageSelectedMaterialsOptions) {
  const uuid = materialsSet?.uuid;

  const {
    emptyMaterialsSetLoading,
    emptyMaterialsSetErrorMessage,
    emptyMaterialsSet
  } = useEmptyMaterialsSet({
    itemCacheKey: materialsSetCacheKey || MaterialsSetCache.showCacheKey(),
    updateMaterialsSetCache
  });

  const handleEmptyMaterialsSet = useCallback(() => {
    uuid && emptyMaterialsSet({ uuid }).then(() => onMaterialsSelect?.([]));
    onClose();
  }, [uuid, emptyMaterialsSet, onClose, onMaterialsSelect]);

  useShowToastOnErrorChange({ error: emptyMaterialsSetErrorMessage });

  const selectedMaterialsCount = size(materialsSet?.selectedMaterials);

  return {
    selectedMaterialsCount,
    emptyMaterialsSetLoading,
    handleEmptyMaterialsSet
  };
}

export default useSelectedMaterialsFloatingCounter;
