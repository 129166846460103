import {
  AssociationIDsFilter,
  BooleanFilter,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsClearItemsFilters,
  FetchItemsShowItem,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  IdFilterType,
  TextFilterType,
  FetchItemsFilterItems,
  DeleteItemGqlStatus,
  DeleteItemGqlQuery,
  ItemGqlError,
  FetchItemCacheKey,
  FetchItemGqlQuery
} from '../../../types';

import {
  FetchCategoriesCacheKey,
  FetchCategoriesFilters,
  FetchCategoriesLimit,
  FetchCategoriesPage,
  FetchCategoriesSort,
  FetchFinCategoriesFilters
} from '../../categories/categoriesTypes';

export interface FetchMaterialsFilters {
  id?: IdFilterType;
  name?: TextFilterType;
  brandId?: IdFilterType;
  materialCategoryIds?: AssociationIDsFilter;
  materialParentCategoryIds?: AssociationIDsFilter;
  favorite?: BooleanFilter;
  nda?: BooleanFilter;
  blocked?: BooleanFilter;
  clientIds?: AssociationIDsFilter;
  sku?: TextFilterType;
}

export type FetchMaterialsFiltersToPersist = (keyof FetchMaterialsFilters)[];

export enum FetchMaterialsSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchMaterialsFilterFields {
  NAME = 'name',
  BRAND_ID = 'brandId',
  MATERIAL_CATEGORY_IDS = 'materialCategoryIds',
  MATERIAL_PARENT_CATEGORY_IDS = 'materialParentCategoryIds',
  BLOCKED = 'blocked',
  FAVORITE = 'favorite',
  NDA = 'nda',
  CREATED_AT = 'createdAt',
  CLIENT_IDS = 'clientIds'
}

export enum FetchMaterialsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  SELECTED_MATERIALS_COUNT_DESC = 'SELECTED_MATERIALS_COUNT_DESC',
  USERS_CHOSEN_MATERIALS_COUNT_DESC = 'USERS_CHOSEN_MATERIALS_COUNT_DESC'
}

export type FetchMaterialsGqlQuery = FetchItemsGqlQuery;
export type FetchMaterialGqlQuery = FetchItemGqlQuery;

export type FetchMaterialCacheKey = FetchItemCacheKey;
export type FetchMaterialsCacheKey = FetchItemsCacheKey;
export type FetchMaterialsCacheKeys = FetchMaterialsCacheKey[];
export type FetchMaterialCategoriesCacheKey = FetchCategoriesCacheKey;

export type FetchMaterialsFetched = FetchItemsFetched;
export type FetchMaterialsErrorMessage = FetchItemsErrorMessage;
export type FetchMaterialsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchMaterialsPage = FetchItemsPage;
export type FetchMaterialsLimit = FetchItemsLimit;
export type FetchMaterialsSort = FetchMaterialsSortTypes[];
export type FetchMaterialsTotalCount = FetchItemsTotalCount;
export type FetchMaterialsFilterMaterials =
  FetchItemsFilterItems<FetchMaterialsFilters>;
export type FetchMaterialsSortMaterials = FetchItemsSortItems;
export type FetchMaterialsPaginateMaterials = FetchItemsPaginateItems;
export type FetchMaterialsPrefetchMaterials = FetchItemsPrefetchItems;
export type FetchMaterialsClearMaterialsFilters = FetchItemsClearItemsFilters;
export type FetchMaterialsShowMaterial = FetchItemsShowItem;
export type FetchMaterialsEditMaterial = FetchItemsEditItem;

export type FetchMaterialCategoriesFilters = FetchCategoriesFilters;
export type FetchFinMaterialCategoriesFilters = FetchFinCategoriesFilters;
export type FetchMaterialCategoriesLimit = FetchCategoriesLimit;
export type FetchMaterialCategoriesSort = FetchCategoriesSort;
export type FetchMaterialCategoriesPage = FetchCategoriesPage;

export type DeleteMaterialGqlQuery = DeleteItemGqlQuery;
export type DeleteMaterialGqlStatus = DeleteItemGqlStatus;
export type MaterialGqlError = ItemGqlError;

export interface FetchMaterialsFiltersFilters {
  scope?: [FetchMaterialsFilterFields];
}
