import { gql } from 'graphql-request';

import {
  ProjectUUID,
  ProjectSelectedMaterialsUUID,
  ProjectSelectedMaterialsMaterialUUID,
  ProjectSelectedMaterialsMaterialName,
  ProjectNanoID
} from '../projectsTypes';

export interface SubmitSelectedMaterialsToProjectQueryResponse {
  uuid: ProjectUUID;
  nanoId: ProjectNanoID;
  selectedMaterials: {
    uuid: ProjectSelectedMaterialsUUID;
    material: {
      uuid: ProjectSelectedMaterialsMaterialUUID;
      name: ProjectSelectedMaterialsMaterialName;
    };
  }[];
}

export const SUBMIT_SELECTED_MATERIALS_TO_PROJECT_QUERY = gql`
  mutation SubmitSelectedMaterialsToProject(
    $uuid: ID!
    $selectedMaterialIds: [ID!]!
  ) {
    submitSelectedMaterialsToProject(
      input: { uuid: $uuid, selectedMaterialIds: $selectedMaterialIds }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
        selectedMaterials {
          uuid
          material {
            uuid
            name
          }
        }
      }
      errors {
        fullMessages
      }
    }
  }
`;
