import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import {
  DownloadSelectedMaterialGqlQuery,
  DownloadSelectedMaterialGqlStatus,
  SelectedMaterialDeviceNanoId,
  SelectedMaterialGqlError,
  SelectedMaterialUUID
} from '../../selectedMaterialsTypes';

interface DownloadSelectedMaterialOptions {
  query: DownloadSelectedMaterialGqlQuery;
  cacheKeys?: string[];
}

export interface DownloadSelectedMaterialResponse<
  DownloadSelectedMaterialRecordType
> {
  downloadSelectedMaterial: {
    status: DownloadSelectedMaterialGqlStatus;
    recordUuid: SelectedMaterialUUID;
    record: DownloadSelectedMaterialRecordType;
    errors: DownloadSelectedMaterialError;
  };
}

export interface DownloadSelectedMaterialInput {
  uuid: SelectedMaterialUUID;
  deviceNanoId: SelectedMaterialDeviceNanoId;
}

export interface DownloadSelectedMaterialError {
  fullMessages: SelectedMaterialGqlError;
}

const action = 'downloadSelectedMaterial';

function useDownloadSelectedMaterial<DownloadSelectedMaterialRecordType>({
  query,
  cacheKeys
}: DownloadSelectedMaterialOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadSelectedMaterialInput,
    DownloadSelectedMaterialResponse<DownloadSelectedMaterialRecordType>,
    DownloadSelectedMaterialError,
    DownloadSelectedMaterialRecordType
  >({ action, query, cacheKeys });

  return {
    downloadSelectedMaterialData: updateQueryData,
    downloadSelectedMaterialError: updateQueryError,
    downloadSelectedMaterialLoading: updateQueryLoading,
    downloadSelectedMaterialErrorMessage: updateQueryErrorMessage,
    downloadSelectedMaterial: updateQuery,
    downloadSelectedMaterialReset: updateQueryReset
  };
}

export default useDownloadSelectedMaterial;
