import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { OnLightboxOpenAction } from '../../../../../../../helpers/LightboxWrapper';

import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../../../helpers/ImageHelper';

import { Files } from '../../../../../../../utils/Files';

const srcSetVersions = {
  '1x': ImageItemImageVersions.MiniThumb48x48,
  '2x': ImageItemImageVersions.MiniThumb96x96,
  '3x': ImageItemImageVersions.MiniThumb144x144
};

import { SelectedMaterialsListItemPreviewSelectedMaterial } from './SelectedMaterialsListItemPreview.types';

interface SelectedMaterialsListItemPreviewProps {
  selectedMaterial: SelectedMaterialsListItemPreviewSelectedMaterial;
  onLightboxOpen: OnLightboxOpenAction;
}

function SelectedMaterialsListItemPreview({
  selectedMaterial,
  onLightboxOpen
}: SelectedMaterialsListItemPreviewProps) {
  const material = selectedMaterial.material;

  const handleOpenLightbox = useCallback(() => {
    if (material?.image) {
      onLightboxOpen(material?.image);
    }
  }, [material, onLightboxOpen]);

  return (
    <div className="aspect-h-1 flex-shrink-0 h-12 overflow-hidden relative rounded-md w-12">
      {material?.image && Files.isImage(material?.image?.file) ? (
        <ButtonHelper className="absolute inset-0" onClick={handleOpenLightbox}>
          <ImageHelper
            className="h-12 w-12"
            src={material.image.file}
            version={ImageItemImageVersions.MiniThumb48x48}
            srcSetVersions={srcSetVersions}
            alt={material.name}
            loading="lazy"
          />
        </ButtonHelper>
      ) : (
        <div className="flex items-center justify-center text-gray-600 absolute inset-0">
          <Icon icon={IconsEnum.PHOTOGRAPH_OUTLINE} className="h-12 w-12" />
        </div>
      )}
    </div>
  );
}

export default SelectedMaterialsListItemPreview;
