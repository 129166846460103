import React, { memo, useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  SelectedMaterialsListItemMaterialsSet,
  SelectedMaterialsListItemSelectedMaterial
} from './SelectedMaterialsListItem.types';
import {
  UpdateMaterialsSetCacheAction,
  FetchMaterialsSetsCacheKeys
} from '../../../../materialsSets/materialsSetsTypes';
import { OnSelectedMaterialsSidebarCloseAction } from '../../../../materials/materialsTypes';
import {
  Checkable,
  OnSetCheckedIds
} from '../../../../../common/hooks/useTableCheckable';

import { SelectedMaterialsListItemPreview } from './components/SelectedMaterialsListItemPreview';
import { SelectedMaterialsListItemDeleteButton } from './components/SelectedMaterialsListItemDeleteButton';
import { MaterialsListItemNda } from '../../../../materials/components/lists/MaterialsListItem/components/MaterialsListItemNda';
import { DownloadSelectedMaterialButton } from '../../buttons/DownloadSelectedMaterialButton';
import { SubmitMaterialToProjectOrTaskModalButton } from '../../../../materials/components/lists/MaterialsListItem/components/SubmitMaterialToProjectOrTaskModalButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { TextWithTooltip } from '../../../../../helpers/TextWithTooltip';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { Checkbox } from '../../../../../helpers/Checkbox';

import { SelectedMaterialsPermissions } from '../../../selectedMaterialsConstants';
import { MaterialsPermissions } from '../../../../materials/materialsConstants';

import { words } from '../../../../../locales/keys/words';

interface SelectedMaterialsListItemProps {
  materialsSet: SelectedMaterialsListItemMaterialsSet;
  selectedMaterial: SelectedMaterialsListItemSelectedMaterial;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  onLightboxOpen: OnLightboxOpenAction;
  onSelectedMaterialsSidebarClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<SelectedMaterialsListItemMaterialsSet>;
  checkable: Checkable;
  checked?: boolean;
  onCheck?: OnSetCheckedIds;
}

function SelectedMaterialsListItem({
  materialsSet,
  selectedMaterial,
  materialsSetCacheKeys,
  onLightboxOpen,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache,
  checkable,
  checked,
  onCheck
}: SelectedMaterialsListItemProps) {
  const material = selectedMaterial.material;

  const handleCheck = useCallback(
    () => onCheck(selectedMaterial.id),
    [onCheck, selectedMaterial.id]
  );

  return (
    <li className="flex items-center overflow-hidden space-x-3 group px-4 -mx-4">
      {checkable && <Checkbox checked={checked} onChange={handleCheck} />}

      <SelectedMaterialsListItemPreview
        selectedMaterial={selectedMaterial}
        onLightboxOpen={onLightboxOpen}
      />

      <div className="flex-1 overflow-hidden text-sm">
        <div className="font-medium truncate">
          <TextWithTooltip
            text={material?.name}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="dark:text-gray-500 text-gray-600 truncate">
          <MaterialsListItemNda material={material} />
          <TextWithTooltip
            text={material?.brand?.name}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
      </div>

      <div className="flex-shrink-0 relative flex items-center justify-end">
        <TooltipSingletonSourceWrapper
          placement={TooltipPlacement.BOTTOM}
          withArrow
        >
          <CheckPermissions
            action={
              MaterialsPermissions.READ_MATERIAL_SUBMIT_TO_TASK_OR_PROJECT_BUTTON
            }
          >
            <SubmitMaterialToProjectOrTaskModalButton
              material={material}
              className="dark:focus:ring-gray-100 dark:hover:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1.5 rounded-md text-gray-400 hover:text-gray-700 absolute focus:relative focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:relative"
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              SelectedMaterialsPermissions.READ_SELECTED_MATERIALS_DOWNLOAD_BUTTON
            }
          >
            <DownloadSelectedMaterialButton
              selectedMaterialUuid={selectedMaterial.uuid}
              className="dark:focus:ring-gray-100 dark:hover:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1.5 rounded-md text-gray-400 hover:text-gray-700 absolute focus:relative focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:relative"
              icon={IconsEnum.DOWNLOAD_OUTLINE}
              iconClassName="h-5 w-5"
              tooltipI18nText={words.download}
              tooltipSingleton
            />
          </CheckPermissions>
        </TooltipSingletonSourceWrapper>

        <SelectedMaterialsListItemDeleteButton
          selectedMaterial={selectedMaterial}
          materialsSet={materialsSet}
          materialsSetCacheKeys={materialsSetCacheKeys}
          className="-mr-2 dark:focus:ring-gray-100 dark:hover:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1.5 rounded-md text-gray-400 hover:text-gray-700 absolute focus:relative focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:relative"
          icon={IconsEnum.CROSS}
          iconClassName="h-5 w-5"
          onSelectedMaterialsSidebarClose={onSelectedMaterialsSidebarClose}
          updateMaterialsSetCache={updateMaterialsSetCache}
        />
      </div>
    </li>
  );
}

export default memo<SelectedMaterialsListItemProps>(SelectedMaterialsListItem);
