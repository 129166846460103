import { gql } from 'graphql-request';

import {
  MaterialsSetID,
  MaterialsSetSelectedMaterialsID,
  MaterialsSetSelectedMaterialsMaterialID,
  MaterialsSetSelectedMaterialsMaterialUUID,
  MaterialsSetSelectedMaterialsUUID,
  MaterialsSetUUID
} from '../materialsSetsTypes';

export interface ToggleMaterialInMaterialsSetQueryResponse {
  id: MaterialsSetID;
  uuid: MaterialsSetUUID;
  selectedMaterials: {
    id: MaterialsSetSelectedMaterialsID;
    uuid: MaterialsSetSelectedMaterialsUUID;
    material: {
      id: MaterialsSetSelectedMaterialsMaterialID;
      uuid: MaterialsSetSelectedMaterialsMaterialUUID;
    };
  }[];
}

export const TOGGLE_MATERIAL_IN_MATERIALS_SET_QUERY = gql`
  mutation ToggleMaterialInMaterialsSet(
    $uuid: ID!
    $materialId: ID!
    $clientMutationId: String
  ) {
    toggleMaterialInMaterialsSet(
      input: {
        uuid: $uuid
        materialId: $materialId
        clientMutationId: $clientMutationId
      }
    ) {
      status
      record {
        id
        uuid
        selectedMaterials {
          id
          uuid
          material {
            id
            uuid
            name
            brand {
              uuid
              name
            }
            image {
              uuid
              file
            }
          }
        }
      }
      errors {
        fullMessages
      }
    }
  }
`;
