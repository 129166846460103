import {
  CreatedAt,
  CreatedAtFilter,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  ItemGqlError,
  UpdatedAt,
  DeletedAt,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID,
  UUIDFilter,
  NanoIDFilter,
  DeviceNanoId,
  DeleteItemCacheKey,
  IdFilterType
} from '../../types';

import {
  MaterialID,
  MaterialUUID,
  MaterialNanoID,
  MaterialName,
  MaterialBrandUUID,
  MaterialBrandName,
  MaterialImageUUID,
  MaterialImageFile,
  MaterialNda,
  MaterialBlocked,
  MaterialFavorite,
  MaterialBrandLocalizedName
} from '../materials/materialsTypes';
import {
  UserFullName,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserClient,
  UserCurrentTeamNanoID
} from '../users/usersTypes';

export type SelectedMaterialID = ID;
export type SelectedMaterialIDs = SelectedMaterialID[];
export type SelectedMaterialUUID = UUID;
export type SelectedMaterialCreatedAt = CreatedAt;
export type SelectedMaterialUpdatedAt = UpdatedAt;
export type SelectedMaterialDeletedAt = DeletedAt;

export type SelectedMaterialDeviceNanoId = DeviceNanoId;

export type SelectedMaterialUserNanoId = UserNanoID;
export type SelectedMaterialUserFullName = UserFullName;
export type SelectedMaterialUserImageUUID = UserImageUUID;
export type SelectedMaterialUserImageFile = UserImageFile;
export type SelectedMaterialUserClient = UserClient;
export type SelectedMaterialUserCurrentTeamNanoID = UserCurrentTeamNanoID;

export type SelectedMaterialMaterialID = MaterialID;
export type SelectedMaterialMaterialUUID = MaterialUUID;
export type SelectedMaterialMaterialNanoID = MaterialNanoID;
export type SelectedMaterialMaterialName = MaterialName;
export type SelectedMaterialMaterialNda = MaterialNda;
export type SelectedMaterialMaterialBlocked = MaterialBlocked;
export type SelectedMaterialMaterialFavorite = MaterialFavorite;

export type SelectedMaterialMaterialBrandUUID = MaterialBrandUUID;
export type SelectedMaterialMaterialBrandName = MaterialBrandName;
export type SelectedMaterialMaterialBrandLocalizedName =
  MaterialBrandLocalizedName;
export type SelectedMaterialMaterialBrand = {
  name: SelectedMaterialMaterialBrandName;
};

export type SelectedMaterialMaterialImageUUID = MaterialImageUUID;
export type SelectedMaterialMaterialImageFile = MaterialImageFile;

export interface FetchSelectedMaterialsFilters {
  id?: IdFilterType;
  taskUuid?: UUIDFilter;
  taskNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  projectNanoId?: NanoIDFilter;
  createdAt?: CreatedAtFilter;
}

export enum FetchSelectedMaterialsSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchSelectedMaterialsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchSelectedMaterialsGqlQuery = FetchItemsGqlQuery;
export type FetchSelectedMaterialGqlQuery = FetchItemGqlQuery;
export type CreateSelectedMaterialGqlQuery = CreateItemGqlQuery;
export type UpdateSelectedMaterialGqlQuery = UpdateItemGqlQuery;
export type DeleteSelectedMaterialGqlQuery = DeleteItemGqlQuery;
export type DownloadSelectedMaterialGqlQuery = UpdateItemGqlQuery;

export type CreateSelectedMaterialGqlStatus = CreateItemGqlStatus;
export type UpdateSelectedMaterialGqlStatus = UpdateItemGqlStatus;
export type DeleteSelectedMaterialGqlStatus = DeleteItemGqlStatus;
export type DownloadSelectedMaterialGqlStatus = UpdateItemGqlStatus;
export type SelectedMaterialGqlError = ItemGqlError;

export type FetchSelectedMaterialsCacheKey = FetchItemsCacheKey;
export type FetchSelectedMaterialCacheKey = FetchItemCacheKey;

export type FetchSelectedMaterialsFetched = FetchItemsFetched;
export type FetchSelectedMaterialsErrorMessage = FetchItemsErrorMessage;
export type FetchSelectedMaterialsIsPlaceholderData =
  FetchItemsIsPlaceholderData;
export type FetchSelectedMaterialsPage = FetchItemsPage;
export type FetchSelectedMaterialsLimit = FetchItemsLimit;
export type FetchSelectedMaterialsSort = FetchSelectedMaterialsSortTypes[];
export type FetchSelectedMaterialsTotalCount = FetchItemsTotalCount;
export type FetchSelectedMaterialsSortSelectedMaterials = FetchItemsSortItems;
export type FetchSelectedMaterialsPaginateSelectedMaterials =
  FetchItemsPaginateItems;
export type FetchSelectedMaterialsPrefetchSelectedMaterials =
  FetchItemsPrefetchItems;
export type FetchSelectedMaterialsShowSelectedMaterial = FetchItemsShowItem;
export type FetchSelectedMaterialsEditSelectedMaterial = FetchItemsEditItem;

export type DeleteSelectedMaterialCacheKey = DeleteItemCacheKey;
export type DeleteSelectedMaterialCacheKeys = DeleteSelectedMaterialCacheKey[];
