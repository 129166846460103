import { FetchMaterialsSetByUserIdResponse } from '../../../../types';

interface GetUpdatedMaterialsSetQueryDataOptions<MaterialsSetByUserIdItemType> {
  updateFunction: (
    item: MaterialsSetByUserIdItemType
  ) => Partial<MaterialsSetByUserIdItemType>;
  previousQueryData: FetchMaterialsSetByUserIdResponse<MaterialsSetByUserIdItemType>;
  itemKey: string;
}

function getUpdatedMaterialsSetQueryData<MaterialsSetByUserIdItemType>({
  updateFunction,
  previousQueryData,
  itemKey
}: GetUpdatedMaterialsSetQueryDataOptions<MaterialsSetByUserIdItemType>): FetchMaterialsSetByUserIdResponse<MaterialsSetByUserIdItemType> | null {
  const previousItem = previousQueryData?.[itemKey];

  if (!previousItem) {
    return null;
  }

  const updateData = updateFunction ? updateFunction(previousItem) : {};

  const updatedItem = {
    ...previousItem,
    optimisticUpdate: true,
    ...updateData
  };

  return {
    ...previousQueryData,
    [itemKey]: updatedItem
  };
}

export default getUpdatedMaterialsSetQueryData;
