import { useCallback } from 'react';

import {
  MaterialFavorite,
  MaterialUUID,
  UpdateMaterialCacheAction,
  ToggleMaterialFavoriteGqlQuery,
  ToggleMaterialFavoriteGqlStatus,
  ToggleMaterialFavoriteCacheKeys,
  ToggleMaterialFavoriteGqlError
} from '../../materialsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleMaterialFavoriteInput {
  clientMutationId?: string;
  uuid: MaterialUUID;
}

interface ToggleMaterialFavoriteError {
  fullMessages: ToggleMaterialFavoriteGqlError;
}

interface ToggleMaterialFavoriteResponse<ToggleMaterialFavoriteItemType> {
  toggleMaterialFavorite: {
    status: ToggleMaterialFavoriteGqlStatus;
    recordUuid: MaterialUUID;
    record: ToggleMaterialFavoriteItemType;
    errors: ToggleMaterialFavoriteError;
  };
}

interface ToggleMaterialFavoriteOptions<ToggleMaterialFavoriteItemType> {
  query: ToggleMaterialFavoriteGqlQuery;
  cacheKeys?: ToggleMaterialFavoriteCacheKeys;
  updateMaterialCache?: UpdateMaterialCacheAction<ToggleMaterialFavoriteItemType>;
}

const action = 'toggleMaterialFavorite';

interface ToggleMaterialFavoriteItem {
  favorite: MaterialFavorite;
}

function useToggleMaterialFavorite<
  ToggleMaterialFavoriteItemType extends ToggleMaterialFavoriteItem
>({
  query,
  cacheKeys,
  updateMaterialCache
}: ToggleMaterialFavoriteOptions<ToggleMaterialFavoriteItemType>) {
  const handleOptimisticUpdate = useCallback<
    (input: ToggleMaterialFavoriteInput) => null | (() => void)
  >(
    (input) =>
      updateMaterialCache?.({
        selector: { uuid: input?.uuid },
        updateFunction: (prevMaterial) => ({
          ...prevMaterial,
          favorite: !prevMaterial?.favorite
        })
      }),
    [updateMaterialCache]
  );

  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleMaterialFavoriteInput,
    ToggleMaterialFavoriteResponse<ToggleMaterialFavoriteItemType>,
    ToggleMaterialFavoriteError,
    ToggleMaterialFavoriteItemType
  >({
    action,
    cacheKeys,
    query,
    onOptimisticUpdate: updateMaterialCache ? handleOptimisticUpdate : undefined
  });

  return {
    toggleMaterialFavoriteData: updateQueryData,
    toggleMaterialFavoriteError: updateQueryError,
    toggleMaterialFavoriteLoading: updateQueryLoading,
    toggleMaterialFavoriteErrorMessage: updateQueryErrorMessage,
    toggleMaterialFavorite: updateQuery,
    toggleMaterialFavoriteReset: updateQueryReset
  };
}

export default useToggleMaterialFavorite;
