import React from 'react';

import {
  MaterialID,
  MaterialUUID,
  MaterialNanoID
} from '../../../../../materialsTypes';
import { ClassName } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useSubmitMaterialToProjectOrTask } from '../../../../../hooks/useSubmitMaterialToProjectOrTask';

import { SelectProjectAndTaskModalButton } from '../../../../../../common/components/modalButtons/SelectProjectAndTaskModalButton';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { materialsKeys, words } from '../../../../../../../locales/keys';

export interface SubmitMaterialToProjectOrTaskModalButtonMaterial {
  uuid: MaterialUUID;
  id: MaterialID;
  nanoId: MaterialNanoID;
}

interface SubmitMaterialToProjectOrTaskModalButtonProps {
  material: SubmitMaterialToProjectOrTaskModalButtonMaterial;
  className?: ClassName;
  tooltipPlacement?: TooltipPlacement;
}

function SubmitMaterialToProjectOrTaskModalButton({
  material,
  className,
  tooltipPlacement = TooltipPlacement.LEFT
}: SubmitMaterialToProjectOrTaskModalButtonProps) {
  const {
    submitMaterialToProjectOrTask,
    submitMaterialToProjectOrTaskError,
    submitMaterialToProjectOrTaskIsLoading,
    submitMaterialToProjectOrTaskReset
  } = useSubmitMaterialToProjectOrTask({ material });

  return (
    <SelectProjectAndTaskModalButton
      className={
        className ||
        'bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow'
      }
      icon={IconsEnum.PAPER_CLIP_SOLID}
      iconClassName="h-5 w-5 stroke-1.75"
      i18nSubmitText={words.attach}
      i18nTitle={materialsKeys.attachSelected}
      tooltipSingleton
      tooltipI18nText={words.attach}
      tooltipPlacement={tooltipPlacement}
      isLoading={submitMaterialToProjectOrTaskIsLoading}
      errorMessage={submitMaterialToProjectOrTaskError}
      onClose={submitMaterialToProjectOrTaskReset}
      onSubmit={submitMaterialToProjectOrTask}
    />
  );
}

export default SubmitMaterialToProjectOrTaskModalButton;
