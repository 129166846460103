import React, { memo } from 'react';

import { SelectedLibraryItemsFloatingCounter } from '../../../common/components/SelectedLibraryItemsFloatingCounter';

import {
  UpdateMaterialsSetCacheAction,
  FetchMaterialsSetsCacheKey
} from '../../../materialsSets/materialsSetsTypes';

import {
  OnSelectedMaterialsSidebarOpenAction,
  OnSelectedMaterialsSidebarCloseAction
} from '../../../materials/materialsTypes';

import useSelectedMaterialsFloatingCounter from './useSelectedMaterialsFloatingCounter';

import {
  SelectedMaterialsFloatingCounterMaterialsSet,
  SelectedMaterialsFloatingCounterOnMaterialsSelect
} from './SelectedMaterialsFloatingCounter.types';

interface SelectedMaterialsFloatingCounterProps {
  materialsSet: SelectedMaterialsFloatingCounterMaterialsSet;
  materialsSetCacheKey?: FetchMaterialsSetsCacheKey;
  isOpen: boolean;
  onOpen: OnSelectedMaterialsSidebarOpenAction;
  onClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<SelectedMaterialsFloatingCounterMaterialsSet>;
  onMaterialsSelect?: SelectedMaterialsFloatingCounterOnMaterialsSelect;
}

function SelectedMaterialsFloatingCounter({
  materialsSet,
  materialsSetCacheKey,
  isOpen,
  onOpen,
  onClose,
  updateMaterialsSetCache,
  onMaterialsSelect
}: SelectedMaterialsFloatingCounterProps) {
  const {
    selectedMaterialsCount,
    emptyMaterialsSetLoading,
    handleEmptyMaterialsSet
  } = useSelectedMaterialsFloatingCounter({
    materialsSet,
    materialsSetCacheKey,
    onClose,
    updateMaterialsSetCache,
    onMaterialsSelect
  });

  return (
    <SelectedLibraryItemsFloatingCounter
      itemsCount={selectedMaterialsCount}
      clearLoading={emptyMaterialsSetLoading}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onClear={handleEmptyMaterialsSet}
    />
  );
}

export default memo<SelectedMaterialsFloatingCounterProps>(
  SelectedMaterialsFloatingCounter
);
