import {
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus,
  TaskGqlError,
  FetchTaskCacheKey,
  TaskUUID,
  TaskSelectedMaterialsID,
  TaskNanoID
} from '../../tasksTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface SubmitSelectedMaterialsToTaskInput {
  uuid: TaskUUID | TaskNanoID;
  selectedMaterialIds: TaskSelectedMaterialsID[];
}

interface SubmitSelectedMaterialsToTaskResponse<
  SubmitSelectedMaterialsToTaskRecordType
> {
  SubmitSelectedMaterialsToTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    record: SubmitSelectedMaterialsToTaskRecordType;
    errors: SubmitSelectedMaterialsToTaskErrors;
  };
}

interface SubmitSelectedMaterialsToTaskErrors {
  fullMessages: TaskGqlError;
}

interface SubmitSelectedMaterialsToTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys: FetchTaskCacheKey[];
}

const action = 'submitSelectedMaterialsToTask';

function useSubmitSelectedMaterialsToTask<
  SubmitSelectedMaterialsToTaskRecordType
>({ query, cacheKeys }: SubmitSelectedMaterialsToTaskOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    SubmitSelectedMaterialsToTaskInput,
    SubmitSelectedMaterialsToTaskResponse<SubmitSelectedMaterialsToTaskRecordType>,
    SubmitSelectedMaterialsToTaskErrors,
    SubmitSelectedMaterialsToTaskRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    submitSelectedMaterialsToTaskData: createQueryData,
    submitSelectedMaterialsToTaskError: createQueryError,
    submitSelectedMaterialsToTaskErrorMessage: createQueryErrorMessage,
    submitSelectedMaterialsToTaskLoading: createQueryLoading,
    submitSelectedMaterialsToTask: createQuery,
    submitSelectedMaterialsToTaskReset: createQueryReset
  };
}

export default useSubmitSelectedMaterialsToTask;
