import { gql } from 'graphql-request';

import {
  MaterialsSetSelectedMaterialsUUID,
  MaterialsSetUUID
} from '../materialsSetsTypes';

export interface EmptyMaterialsSetInput {
  clientMutationId?: string;
  uuid: MaterialsSetUUID;
}

export interface EmptyMaterialsSetError {
  fullMessages: string[] | null;
}

export interface EmptyMaterialsSetResponse {
  emptyMaterialsSet: {
    status: string;
    errors: EmptyMaterialsSetError;
  };
}

export interface EmptyMaterialsSetQueryResponse {
  uuid: MaterialsSetUUID;
  selectedMaterials: {
    uuid: MaterialsSetSelectedMaterialsUUID;
  }[];
}

export const action = 'emptyMaterialsSet';

export const EMPTY_MATERIALS_SET_QUERY = gql`
  mutation EmptyMaterialsSet($uuid: ID!) {
    emptyMaterialsSet(input: { uuid: $uuid }) {
      status
      recordUuid
      record {
        uuid
        selectedMaterials {
          uuid
        }
      }
      errors {
        fullMessages
      }
    }
  }
`;
