import { useCallback } from 'react';
import { useQueryClient, QueryKey } from 'react-query';

import {
  FetchMaterialsSetByUserIdResponse,
  UpdateMaterialsSetByUserIdCacheAction
} from '../../types';

import { getUpdatedMaterialsSetQueryData } from './utils/getUpdatedMaterialsSetQueryData';

interface UpdateMaterialsSetCacheOptions {
  fullCacheKey: QueryKey;
  itemKey: string;
}

function useUpdateMaterialsSetCache<MaterialsSetByUserIdItemType>({
  fullCacheKey,
  itemKey
}: UpdateMaterialsSetCacheOptions) {
  const queryClient = useQueryClient();

  const useUpdateMaterialsSetCache = useCallback<
    UpdateMaterialsSetByUserIdCacheAction<MaterialsSetByUserIdItemType>
  >(
    ({ updateFunction }) => {
      queryClient.cancelQueries(fullCacheKey);

      const previousQueryData =
        queryClient.getQueryData<
          FetchMaterialsSetByUserIdResponse<MaterialsSetByUserIdItemType>
        >(fullCacheKey);

      if (!previousQueryData || !previousQueryData[itemKey]) {
        return null;
      }

      const updatedQueryData =
        getUpdatedMaterialsSetQueryData<MaterialsSetByUserIdItemType>({
          updateFunction,
          previousQueryData,
          itemKey
        });

      if (!updatedQueryData) {
        return null;
      }

      queryClient.setQueryData<
        FetchMaterialsSetByUserIdResponse<MaterialsSetByUserIdItemType>
      >(fullCacheKey, updatedQueryData);

      return () => {
        queryClient.setQueryData<
          FetchMaterialsSetByUserIdResponse<MaterialsSetByUserIdItemType>
        >(fullCacheKey, previousQueryData);
      };
    },
    [fullCacheKey, queryClient, itemKey]
  );

  return useUpdateMaterialsSetCache;
}

export default useUpdateMaterialsSetCache;
