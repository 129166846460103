import { useCallback } from 'react';
import compact from 'lodash/compact';

import {
  FetchMaterialsCacheKeys,
  UpdateMaterialCacheAction
} from '../../../materialsTypes';
import { MaterialFavoriteButtonMaterial } from './MaterialFavoriteButton.types';
import { FetchMaterialsSetsCacheKeys } from '../../../../materialsSets/materialsSetsTypes';

import {
  TOGGLE_MATERIAL_FAVORITE_QUERY,
  ToggleMaterialFavoriteQueryResponse
} from '../../../queries/toggleMaterialFavorite.query';

import { useToggleMaterialFavorite } from '../../../hooks/useToggleMaterialFavorite';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { MaterialsSetCache } from '../../../../materialsSets/MaterialsSetCache';
import { MaterialCache } from '../../../MaterialCache';

interface useMaterialFavoriteButtonProps {
  material: MaterialFavoriteButtonMaterial;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  updateMaterialCache?: UpdateMaterialCacheAction<MaterialFavoriteButtonMaterial>;
}

function useMaterialFavoriteButton({
  material,
  materialsCacheKeys,
  materialsSetCacheKeys,
  updateMaterialCache
}: useMaterialFavoriteButtonProps) {
  const {
    toggleMaterialFavorite,
    toggleMaterialFavoriteErrorMessage,
    toggleMaterialFavoriteLoading
  } = useToggleMaterialFavorite<ToggleMaterialFavoriteQueryResponse>({
    query: TOGGLE_MATERIAL_FAVORITE_QUERY,
    cacheKeys: compact([
      MaterialCache.indexCacheKey(),
      MaterialsSetCache.showCacheKey(),
      ...(materialsCacheKeys || []),
      ...(materialsSetCacheKeys || [])
    ]),
    updateMaterialCache
  });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleMaterialFavorite({ uuid: material.uuid }),
    [material, toggleMaterialFavorite]
  );

  useShowToastOnErrorChange({ error: toggleMaterialFavoriteErrorMessage });

  return {
    handleToggleFavorite,
    toggleMaterialFavoriteLoading
  };
}

export default useMaterialFavoriteButton;
