import { useCallback } from 'react';
import compact from 'lodash/compact';

import { FetchMaterialsCacheKeys } from '../../../../../../materials/materialsTypes';
import { FetchMaterialsSetsCacheKeys } from '../../../../../../materialsSets/materialsSetsTypes';

import {
  TOGGLE_MATERIAL_FAVORITE_QUERY,
  ToggleMaterialFavoriteQueryResponse
} from '../../../../../../materials/queries/toggleMaterialFavorite.query';

import { useToggleMaterialFavorite } from '../../../../../../materials/hooks/useToggleMaterialFavorite';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { MaterialCache } from '../../../../../../materials/MaterialCache';
import { MaterialsSetCache } from '../../../../../../materialsSets/MaterialsSetCache';

import { SelectedMaterialsListItemLightboxFavoriteButtonSelectedMaterial } from './SelectedMaterialsListItemLightboxFavoriteButton.types';

interface SelectedMaterialsListItemLightboxFavoriteButtonOptions {
  selectedMaterial: SelectedMaterialsListItemLightboxFavoriteButtonSelectedMaterial;
  materialsCacheKeys?: FetchMaterialsCacheKeys;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
}

function useSelectedMaterialsListItemLightboxFavoriteButton({
  selectedMaterial,
  materialsCacheKeys,
  materialsSetCacheKeys
}: SelectedMaterialsListItemLightboxFavoriteButtonOptions) {
  const {
    toggleMaterialFavorite,
    toggleMaterialFavoriteLoading,
    toggleMaterialFavoriteErrorMessage
  } = useToggleMaterialFavorite<ToggleMaterialFavoriteQueryResponse>({
    query: TOGGLE_MATERIAL_FAVORITE_QUERY,
    cacheKeys: compact([
      MaterialCache.indexCacheKey(),
      MaterialsSetCache.showCacheKey(),
      ...(materialsCacheKeys || []),
      ...(materialsSetCacheKeys || [])
    ])
  });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleMaterialFavorite({ uuid: selectedMaterial?.material?.uuid }),
    [selectedMaterial, toggleMaterialFavorite]
  );

  useShowToastOnErrorChange({ error: toggleMaterialFavoriteErrorMessage });

  return {
    toggleMaterialFavoriteLoading,
    handleToggleFavorite
  };
}

export default useSelectedMaterialsListItemLightboxFavoriteButton;
