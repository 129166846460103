import { useCallback } from 'react';

import { MaterialID, MaterialUUID, MaterialNanoID } from '../../materialsTypes';
import { ProjectUUID } from '../../../projects/projectsTypes';

import {
  FetchMaterialsSetByUserIdQueryResponse,
  FETCH_MATERIALS_SET_BY_USER_ID_QUERY
} from '../../../materialsSets/queries/fetchMaterialsSetByUserId.query';
import { TOGGLE_MATERIAL_IN_MATERIALS_SET_QUERY } from '../../../materialsSets/queries/toggleMaterialInMaterialsSet.query';
import {
  SubmitSelectedMaterialsToProjectQueryResponse,
  SUBMIT_SELECTED_MATERIALS_TO_PROJECT_QUERY
} from '../../../projects/queries/submitSelectedMaterialsToProject.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useMaterialsSetByUserId } from '../../../materialsSets/hooks/useMaterialsSetByUserId';
import { useToggleMaterialInMaterialsSet } from '../../../materialsSets/hooks/useToggleMaterialInMaterialsSet';
import { useSubmitSelectedMaterialsToProject } from '../../../projects/hooks/useSubmitSelectedMaterialsToProject';

import { MaterialsSetCache } from '../../../materialsSets/MaterialsSetCache';
import { MaterialsSetScope } from '../../../materialsSets/MaterialsSetScope';

interface SubmitMaterialToProjectOptionsMaterial {
  uuid: MaterialUUID;
  id: MaterialID;
  nanoId: MaterialNanoID;
}

interface SubmitMaterialToProjectOptions {
  material: SubmitMaterialToProjectOptionsMaterial;
}

function useSubmitMaterialToProject({
  material
}: SubmitMaterialToProjectOptions) {
  const currentUser = useCurrentUser();

  const {
    materialsSet,
    materialsSetError,
    materialsSetLoading,
    updateMaterialsSetCache
  } = useMaterialsSetByUserId<FetchMaterialsSetByUserIdQueryResponse>({
    cacheKey: MaterialsSetCache.submitToProjectCacheKey(),
    query: FETCH_MATERIALS_SET_BY_USER_ID_QUERY,
    userId: currentUser.id,
    scope: MaterialsSetScope.submitToProjectScope(currentUser.id)
  });

  const {
    toggleMaterialInMaterialsSetErrorMessage,
    toggleMaterialInMaterialsSet,
    toggleMaterialInMaterialsSetLoading
  } = useToggleMaterialInMaterialsSet({
    query: TOGGLE_MATERIAL_IN_MATERIALS_SET_QUERY,
    cacheKeys: [MaterialsSetCache.submitToProjectCacheKey()],
    material,
    updateMaterialsSetCache
  });

  const {
    submitSelectedMaterialsToProjectErrorMessage,
    submitSelectedMaterialsToProjectLoading,
    submitSelectedMaterialsToProject,
    submitSelectedMaterialsToProjectReset
  } = useSubmitSelectedMaterialsToProject<SubmitSelectedMaterialsToProjectQueryResponse>(
    {
      query: SUBMIT_SELECTED_MATERIALS_TO_PROJECT_QUERY,
      cacheKeys: [MaterialsSetCache.submitToProjectCacheKey()]
    }
  );

  const submitMaterialToProject = useCallback<
    (projectUuid: ProjectUUID) => Promise<unknown>
  >(
    async (projectUuid) => {
      const data = await toggleMaterialInMaterialsSet({
        materialId: material.nanoId,
        uuid: materialsSet.uuid
      });

      return submitSelectedMaterialsToProject({
        uuid: projectUuid,
        selectedMaterialIds:
          data.toggleMaterialInMaterialsSet?.record?.selectedMaterials?.map(
            (selectedMaterial) => selectedMaterial.id
          )
      });
    },
    [
      material.nanoId,
      materialsSet,
      submitSelectedMaterialsToProject,
      toggleMaterialInMaterialsSet
    ]
  );

  return {
    submitMaterialToProject,
    submitMaterialToProjectReset: submitSelectedMaterialsToProjectReset,
    submitMaterialToProjectError:
      materialsSetError ||
      toggleMaterialInMaterialsSetErrorMessage ||
      submitSelectedMaterialsToProjectErrorMessage,
    submitMaterialToProjectIsLoading:
      materialsSetLoading ||
      toggleMaterialInMaterialsSetLoading ||
      submitSelectedMaterialsToProjectLoading
  };
}

export default useSubmitMaterialToProject;
