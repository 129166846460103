import { MaterialUUID } from '../../../../../materials/materialsTypes';
import {
  SelectedMaterialCreatedAt,
  SelectedMaterialUpdatedAt,
  SelectedMaterialDeletedAt,
  SelectedMaterialID,
  SelectedMaterialUUID
} from '../../../../../selectedMaterials/selectedMaterialsTypes';

import { generateNanoId } from '../../../../../../utils/generateNanoId';

interface TempSelectedMaterialMaterial {
  uuid: MaterialUUID;
}

interface TempSelectedMaterialResult {
  id: SelectedMaterialID;
  uuid: SelectedMaterialUUID;
  createdAt: SelectedMaterialCreatedAt;
  updatedAt: SelectedMaterialUpdatedAt;
  deletedAt: SelectedMaterialDeletedAt;
  material: TempSelectedMaterialMaterial;
}

class TempSelectedMaterial {
  static lastId = 999999999;

  static create(
    material: TempSelectedMaterialMaterial
  ): TempSelectedMaterialResult {
    const jsonDateNow = JSON.stringify(new Date());
    return {
      material,
      id: `${TempSelectedMaterial.lastId++}`,
      uuid: generateNanoId() as SelectedMaterialUUID,
      createdAt: jsonDateNow,
      updatedAt: jsonDateNow,
      deletedAt: null
    };
  }
}

export default TempSelectedMaterial;
