import {
  UpdateProjectGqlQuery,
  UpdateProjectGqlStatus,
  ProjectGqlError,
  FetchProjectCacheKey,
  ProjectUUID,
  ProjectSelectedMaterialsID,
  ProjectNanoID
} from '../../projectsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface SubmitSelectedMaterialsToProjectInput {
  uuid: ProjectUUID | ProjectNanoID;
  selectedMaterialIds: ProjectSelectedMaterialsID[];
}

interface SubmitSelectedMaterialsToProjectResponse<
  SubmitSelectedMaterialsToProjectRecordType
> {
  SubmitSelectedMaterialsToProject: {
    status: UpdateProjectGqlStatus;
    recordUuid: ProjectUUID;
    recordNanoId: ProjectNanoID;
    record: SubmitSelectedMaterialsToProjectRecordType;
    errors: SubmitSelectedMaterialsToProjectErrors;
  };
}

interface SubmitSelectedMaterialsToProjectErrors {
  fullMessages: ProjectGqlError;
}

interface SubmitSelectedMaterialsToProjectOptions {
  query: UpdateProjectGqlQuery;
  cacheKeys: FetchProjectCacheKey[];
}

const action = 'submitSelectedMaterialsToProject';

function useSubmitSelectedMaterialsToProject<
  SubmitSelectedMaterialsToProjectRecordType
>({ query, cacheKeys }: SubmitSelectedMaterialsToProjectOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    SubmitSelectedMaterialsToProjectInput,
    SubmitSelectedMaterialsToProjectResponse<SubmitSelectedMaterialsToProjectRecordType>,
    SubmitSelectedMaterialsToProjectErrors,
    SubmitSelectedMaterialsToProjectRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    submitSelectedMaterialsToProjectData: createQueryData,
    submitSelectedMaterialsToProjectError: createQueryError,
    submitSelectedMaterialsToProjectErrorMessage: createQueryErrorMessage,
    submitSelectedMaterialsToProjectLoading: createQueryLoading,
    submitSelectedMaterialsToProject: createQuery,
    submitSelectedMaterialsToProjectReset: createQueryReset
  };
}

export default useSubmitSelectedMaterialsToProject;
