import React from 'react';

import { ClassName, I18nText } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  UpdateMaterialsSetCacheAction,
  FetchMaterialsSetsCacheKeys
} from '../../../../../../materialsSets/materialsSetsTypes';

import { OnSelectedMaterialsSidebarCloseAction } from '../../../../../../materials/materialsTypes';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import useSelectedMaterialsListItemDeleteButton from './useSelectedMaterialsListItemDeleteButton';

import {
  SelectedMaterialsListItemDeleteButtonSelectedMaterial,
  SelectedMaterialsListItemDeleteButtonMaterialsSet
} from './SelectedMaterialsListItemDeleteButton.types';

interface SelectedMaterialsListItemDeleteButtonProps {
  selectedMaterial: SelectedMaterialsListItemDeleteButtonSelectedMaterial;
  materialsSet: SelectedMaterialsListItemDeleteButtonMaterialsSet;
  materialsSetCacheKeys?: FetchMaterialsSetsCacheKeys;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  onSelectedMaterialsSidebarClose: OnSelectedMaterialsSidebarCloseAction;
  updateMaterialsSetCache: UpdateMaterialsSetCacheAction<SelectedMaterialsListItemDeleteButtonMaterialsSet>;
}

function SelectedMaterialsListItemDeleteButton({
  selectedMaterial,
  materialsSet,
  materialsSetCacheKeys,
  className,
  iconClassName,
  icon,
  i18nText,
  onSelectedMaterialsSidebarClose,
  updateMaterialsSetCache
}: SelectedMaterialsListItemDeleteButtonProps) {
  const { handleToggleSelected } = useSelectedMaterialsListItemDeleteButton({
    selectedMaterial,
    materialsSet,
    materialsSetCacheKeys,
    onSelectedMaterialsSidebarClose,
    updateMaterialsSetCache
  });

  return (
    <>
      <PureIconButtonHelper
        className={className}
        icon={icon}
        iconClassName={iconClassName}
        i18nText={i18nText}
        onClick={handleToggleSelected}
      />
    </>
  );
}

export default SelectedMaterialsListItemDeleteButton;
