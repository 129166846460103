import { gql } from 'graphql-request';

import {
  MaterialsSetID,
  MaterialsSetSelectedMaterialsID,
  MaterialsSetSelectedMaterialsMaterialBrandName,
  MaterialsSetSelectedMaterialsMaterialBlocked,
  MaterialsSetSelectedMaterialsMaterialFavorite,
  MaterialsSetSelectedMaterialsMaterialBrandUUID,
  MaterialsSetSelectedMaterialsMaterialID,
  MaterialsSetSelectedMaterialsMaterialImageFile,
  MaterialsSetSelectedMaterialsMaterialImageUUID,
  MaterialsSetSelectedMaterialsMaterialName,
  MaterialsSetSelectedMaterialsMaterialNda,
  MaterialsSetSelectedMaterialsMaterialUUID,
  MaterialsSetSelectedMaterialsMaterialNanoID,
  MaterialsSetSelectedMaterialsUUID,
  MaterialsSetUUID
} from '../materialsSetsTypes';

export interface FetchMaterialsSetByUserIdQueryResponse {
  id: MaterialsSetID;
  uuid: MaterialsSetUUID;
  selectedMaterials: {
    id: MaterialsSetSelectedMaterialsID;
    uuid: MaterialsSetSelectedMaterialsUUID;
    material: {
      id: MaterialsSetSelectedMaterialsMaterialID;
      uuid: MaterialsSetSelectedMaterialsMaterialUUID;
      nanoId: MaterialsSetSelectedMaterialsMaterialNanoID;
      name: MaterialsSetSelectedMaterialsMaterialName;
      nda: MaterialsSetSelectedMaterialsMaterialNda;
      blocked: MaterialsSetSelectedMaterialsMaterialBlocked;
      favorite: MaterialsSetSelectedMaterialsMaterialFavorite;
      image: {
        uuid: MaterialsSetSelectedMaterialsMaterialImageUUID;
        file: MaterialsSetSelectedMaterialsMaterialImageFile;
      };
      brand: {
        uuid: MaterialsSetSelectedMaterialsMaterialBrandUUID;
        name: MaterialsSetSelectedMaterialsMaterialBrandName;
      };
    };
  }[];
}

export const FETCH_MATERIALS_SET_BY_USER_ID_QUERY = gql`
  query MaterialsSetByUserId($userId: ID!, $scope: ID) {
    materialsSetByUserId(userId: $userId, scope: $scope) {
      id
      uuid
      selectedMaterials {
        id
        uuid
        material {
          id
          uuid
          nanoId
          name
          nda
          blocked
          favorite
          image {
            uuid
            file
          }
          brand {
            uuid
            name
          }
        }
      }
    }
  }
`;
