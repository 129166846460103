import { FetchSelectedMaterialsSortTypes } from './selectedMaterialsTypes';

export const INITIAL_SELECTED_MATERIALS_FILTERS = {};
export const INITIAL_SELECTED_MATERIALS_PAGE = 1;
export const INITIAL_SELECTED_MATERIALS_SORT = [
  FetchSelectedMaterialsSortTypes.CREATED_AT_DESC
];
export const INITIAL_SELECTED_MATERIALS_LIMIT = 50;

export enum SelectedMaterialsPermissions {
  READ_SELECTED_MATERIALS_DOWNLOAD_BUTTON = 'read_selected_materials_download_button',
  READ_SELECTED_MATERIAL_FAVORITE_BUTTON = 'read_selected_material_favorite_button',
  CHANGE_SELECTED_MATERIALS_CHECKED = 'change_selected_materials_checked'
}
